import { GvolFetch } from "../components/gvol-fetch/gvol-fetch"

export async function AddComponentToWorkspaceById(id: string, component: any, token: string) {
  try {
    const body = JSON.stringify({ component })

    const link = new GvolFetch(
      `/api/workspaces/${id}/components/add`,
      {
        method: "PUT",
        headers: {
          "x-auth": token,
          "Content-Type": "application/json",
        },
      },
      body
    )

    const data = await link.fetchData()

    return data
  } catch (error) {
    console.log(error)
    return {}
  }
}
export async function RemoveComponentToWorkspaceById(id: string, componentId: any, token: string) {
  try {
    const body = JSON.stringify({ componentId })

    const link = new GvolFetch(
      `/api/workspaces/${id}/components/remove`,
      {
        method: "PUT",
        headers: {
          "x-auth": token,
          "Content-Type": "application/json",
        },
      },
      body
    )

    const data = await link.fetchData()

    return data
  } catch (error) {
    console.log(error)
    return {}
  }
}

export async function saveWorkspace(workspace: string, token: string) {
  const body = workspace

  const link = new GvolFetch(
    `/api/workspaces`,
    {
      method: "POST",
      headers: {
        "x-auth": token,
        "Content-Type": "application/json",
      },
    },
    body
  )

  const data = await link.fetchData()
  return data
}

export async function saveWorkspaceById(id: string, component: any, token: string) {
  const body = JSON.stringify({ data: component })

  const link = new GvolFetch(
    `/api/workspaces/${id}`,
    {
      method: "POST",
      headers: {
        "x-auth": token,
        "Content-Type": "application/json",
      },
    },
    body
  )

  const data = await link.fetchData()

  return data
}

export async function getWorkspaces(token: string) {
  const link = new GvolFetch(`/api/workspaces`, {
    method: "GET",
    headers: {
      "x-auth": token,
      "Content-Type": "application/json",
    },
  })

  const data = await link.fetchData()

  return data
}

export async function getWorkspaceByID(id: string, token: string) {
  const link = new GvolFetch(`/api/workspaces/${id}`, {
    method: "GET",
    headers: {
      "x-auth": token,
      "Content-Type": "application/json",
    },
  })

  const data = await link.fetchData()

  return data
}

export async function deleteWorkspacesByID(id: string, token: string) {
  const link = new GvolFetch(`/api/workspaces/${id}`, {
    method: "DELETE",
    headers: {
      "x-auth": token,
      "Content-Type": "application/json",
    },
  })

  const data = await link.fetchData()

  return data
}
