import { useContext } from "react"

import { GlobalNotificationDispatch } from "../contexts/notification/notification-context"
import { info } from "../contexts/notification/notification-helpers/info"
import { defaultAlert } from "../contexts/notification/notification-helpers/alert"
import { error } from "../contexts/notification/notification-helpers/error"
import { warning } from "../contexts/notification/notification-helpers/warning"

export const useNotifications = () => {
  const dispatch = useContext(GlobalNotificationDispatch)
  function buildInfo({ title, description }: { title: string; description?: string }) {
    info({ title, description }, dispatch)
  }
  function buildWarning({ title, description }: { title: string; description?: string }) {
    warning({ title, description }, dispatch)
  }
  function buildError({ title, description }: { title: string; description?: string }) {
    error({ title, description }, dispatch)
  }
  function buildAlert({ title, description }: { title: string; description?: string }) {
    defaultAlert({ title, description }, dispatch)
  }
  return {
    info: buildInfo,
    alert: buildAlert,
    error: buildError,
    warning: buildWarning,
  }
}
