import React, { createContext, FC, useReducer } from "react"

const initialMenuState: IMenuState = {
  showMenu: false,
}

export interface IMenuState {
  showMenu: boolean
}
export const GlobalMenuState = createContext<IMenuState>(initialMenuState)
export const GlobalMenuDispatch = createContext(null)

export const menuActions = {
  SET_MENU: "SET_MENU",
}

function menuReducer(state: IMenuState, action: { type: string; payload?: any }) {
  switch (action.type) {
    case menuActions.SET_MENU: {
      return action.payload
    }
    default: {
      return state
    }
  }
}

export const GlobalMenuProvider: FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer(menuReducer, initialMenuState)

  return (
    <GlobalMenuState.Provider value={state}>
      <GlobalMenuDispatch.Provider value={dispatch}>{children}</GlobalMenuDispatch.Provider>
    </GlobalMenuState.Provider>
  )
}
