import { initializeApp } from "firebase/app"
import * as Auth from "firebase/auth"
import * as Database from "firebase/database"

const firebaseCredentials = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
}

const app = initializeApp(firebaseCredentials)
const database = Database.getDatabase(app)
const auth = Auth.getAuth(app)

const authHelpers = Auth
const databaseHelpers = Database

export { app, database, auth, authHelpers, databaseHelpers }
