import React from "react"
import { Transition } from "@headlessui/react"
import {
  ExclamationCircleIcon,
  ArrowTopRightOnSquareIcon,
  InformationCircleIcon,
  StarIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline"
import { useState, useEffect } from "react"
import { getUniqueKey } from "../../../utils/get-unique-key"
import { INotification, NotificationStatusType } from "../notification-context"

function getTypeColor(type: NotificationStatusType) {
  switch (type) {
    case "error": {
      return "bg-red-500"
    }
    case "warning": {
      return "bg-highlight-500"
    }
    case "info": {
      return "bg-secondary-500"
    }
    case "default":
    default: {
      return "bg-primary-500"
    }
  }
}
function getTypeIcon(type: NotificationStatusType) {
  switch (type) {
    case "error": {
      return <ExclamationCircleIcon className="h-6 w-6 stroke-neutral-100" />
    }
    case "warning": {
      return <ArrowTopRightOnSquareIcon className="h-6 w-6 stroke-neutral-100" />
    }
    case "info": {
      return <InformationCircleIcon className="h-6 w-6 stroke-neutral-100" />
    }
    case "default":
    default: {
      return <StarIcon className="h-6 w-6 stroke-neutral-100" />
    }
  }
}
interface Props extends INotification {
  remove(): void
  onClick(): void
}
export const NotificationCard = (props: Props) => {
  const { onClick, title, type, description } = props
  const [showing, setShowing] = useState(true)
  const [timer, setTimer] = useState(null)
  function startTimer() {
    const t = setTimeout(() => {
      setShowing(false)
      props.remove()
    }, 2000)
    setTimer(t)
  }
  useEffect(() => {
    startTimer()
  }, [])
  return (
    <Transition key={getUniqueKey(".notification")} show={showing}>
      <Transition.Child
        as={"div"}
        enter="transition-opacity ease-linear duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-linear duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          onMouseOver={() => {
            clearTimeout(timer)
          }}
          onMouseLeave={() => [startTimer()]}
          key={getUniqueKey(".notification")}
          className="flex gap justify-between items-stretch  shadow-md rounded-md [width:40ch] [min-height:4em]"
        >
          <div className={`${getTypeColor(type)} px-2 rounded-tl-md rounded-bl-md grid place-items-center  `}>
            {getTypeIcon(type)}
          </div>
          <div className="bg-neutral-100 dark:bg-neutral-800 text-neutral-700 dark:text-neutral-200 flex-1 p-2 justify-center flex flex-col ">
            <div className="text-sm">{title}</div>
            {description ? <div className="text-xs">{description}</div> : null}
          </div>
          <div
            onClick={onClick}
            className="cursor-pointer grid place-items-center bg-neutral-50 dark:bg-neutral-600 flex-grow max-w-fit p-2   rounded-tr-md rounded-br-md"
          >
            <XMarkIcon className="h-3 w-3 " />
          </div>
        </div>
      </Transition.Child>
    </Transition>
  )
}
