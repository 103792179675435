import React, { createContext, FC, useReducer, useEffect, useState } from "react"
import { LoadingPage } from "../../components/loading-page/loading-page"
import { isBrowser } from "../../utils/is-browser"
import { setItem, getItem } from "../../utils/token-helpers"

export const GlobalThemeState = createContext<ThemeEnum>("dark")
export const GlobalThemeDispatch = createContext(null)

export const themeActions = {
  SET_THEME: "SET_THEME",
}
export type ThemeEnum = "dark" | "light"

const THEME_KEY = "THEMEX"

function setThemeOnDocument(prev, theme: ThemeEnum) {
  if (isBrowser()) {
    document.documentElement.classList.remove(prev)
    if (theme === "dark") {
      document.documentElement.classList.add(theme)
    }
  }
}

function themeReducer(state: ThemeEnum, action: { type: string; payload?: any }) {
  switch (action.type) {
    case themeActions.SET_THEME: {
      setThemeOnDocument(state, action.payload)
      setItem(THEME_KEY, action.payload)
      return action.payload
    }
    default: {
      return state
    }
  }
}

export const GlobalThemeProvider: FC<any> = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [state, dispatch] = useReducer(themeReducer, getItem(THEME_KEY) || "dark")
  useEffect(() => {
    const getInit = () => {
      let defaultTheme = ""
      if (isBrowser()) {
        const isDark = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
        defaultTheme = isDark ? "dark" : "light"
      }

      const userTheme = getItem(THEME_KEY) || defaultTheme
      setThemeOnDocument(state, userTheme)
      if (userTheme) {
        dispatch({
          type: themeActions.SET_THEME,
          payload: userTheme,
        })
      }
      setLoading(false)
    }
    getInit()
  }, [])

  if (loading) {
    return <LoadingPage title="Setting Theme" />
  }

  return (
    <GlobalThemeState.Provider value={state}>
      <GlobalThemeDispatch.Provider value={dispatch}>{children}</GlobalThemeDispatch.Provider>
    </GlobalThemeState.Provider>
  )
}
