import { useFirebase } from "../../../hooks/use-firebase"
import { fetchUser } from "../../../hooks/use-get-user"
import { IUserProps } from "../user-context"

export async function getFullUser(): Promise<Partial<IUserProps>> {
  let completeUser = {}

  const { auth } = useFirebase()

  const fbUser = auth.currentUser
  if (fbUser) {
    const userToken = await fbUser.getIdToken()

    const { displayName, email, emailVerified, photoURL } = fbUser

    completeUser = {
      name: displayName,
      email,
      emailVerified,
      photo: photoURL,
    }

    const gvolUser = await fetchUser(userToken)

    completeUser = {
      ...completeUser,
      ...gvolUser,
      isAuthenticated: true,
    }
  }

  return completeUser
}
