import { gql } from "@apollo/client"

export const GET_V2_USER_QUERY = gql`
  query GetV2User($token: String) {
    User: UserV2(token: $token) {
      token
      email
      name
      isPaid
      isTrialing
      trialEnd
      apiKeys {
        key
        name
        createdAt
      }
      workspaces {
        id
        name
        data
      }
      layouts
    }
  }
`
