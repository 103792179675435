import { gql } from "@apollo/client"

export const GET_USER_QUERY = gql`
  query GetUser($token: String) {
    User(token: $token) {
      id
      token
      email
      name
      payload {
        stripe {
          customerId
        }
        stripe_dev {
          customerId
        }
        stripe_prod {
          customerId
        }
      }
      plans {
        stripe_reference
        product_id
      }
      subscriptions {
        id
        product_id
        created
        current_period_end
        canceled_at
        trial_end
        isTrial
        isCanceled
        isPaid
      }
    }
  }
`
