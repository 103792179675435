const ur = process.env.GATSBY_API_URL || process.env.API_URL
const apiUrl = ur === "/api/gql" ? "http://localhost:4000" : ur

export class GvolFetch {
  private link: Request
  constructor(
    private readonly url: string,
    private readonly info?: RequestInit,
    private readonly body?: string
  ) {
    this.link = new Request(apiUrl + this.url, this.info)
  }

  public setHeader({ key, value }: { key: string; value: string }) {
    this.link.headers.set(key, value)
  }

  public async fetchData() {
    const resp = await fetch(this.link.url, {
      ...this.link,
      headers: this.link.headers,
      method: this.link.method || "GET",
      body: this.body,
    })
    const data = await resp.json()
    return data
  }
}
