import { GvolFetch } from "../components/gvol-fetch/gvol-fetch"

export async function addOrUpdateLayouts(data: any, token: string) {
  try {
    const body = JSON.stringify({ data })

    const link = new GvolFetch(
      `/api/layouts`,
      {
        method: "POST",
        headers: {
          "x-auth": token,
          "Content-Type": "application/json",
        },
      },
      body
    )

    const resp = await link.fetchData()

    return resp
  } catch (error) {
    console.log(error)
    return {}
  }
}
