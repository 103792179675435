const NAME = "Amberdata Derivatives"
const CONTENT =
  "Oh, Hey Fam! We in here... Welcome to the Amberdata Derivatives Console! Please get comfortable and enjoy all the best data."
const URL = "https://amberdata.io/"
export function postConsoleMessage() {
  // @ts-ignore
  window.gvol = {
    faqs: {
      whereCanIFindYou() {
        return "https://amberdata.io"
      },
      doYouHaveANewLetter() {
        return "https://genesisvolatility.substack.com/"
      },
      getResources() {
        const resources = {
          url: "https://amberdata.io",
          newsLetter: "https://genesisvolatility.substack.com/",
          twitter: "https://twitter.com/GenesisVol",
          youtube: "https://www.youtube.com/channel/UCW4nDdo46EjaDEd5R2RrkEw",
        }
        console.table(resources, ["font-size:0.8rem;line-height:1.15rem;background:#432;color:#fff;padding:0.4rem;"])
        return resources
      },
    },
  }

  const header =
    "font-size:2rem;line-height:1.25;color:#fff;background:#E06DA3;padding-left:2rem;padding-right:2rem;font-weight:bolder;"
  const separator = "padding:0;"
  const body = "font-size:0.8rem;line-height:1.15rem;background:#432;color:#fff;padding:0.4rem;"
  const externalLink =
    "font-size:1rem;line-height:1.15rem;background:#E06DA3;font-weight:bold;text-decoration:underline;color:#fff;padding:0.1rem;"
  const link = "font-size:1.5em;"
  console.info(
    `
%c ${NAME} %c
%c${CONTENT}`,
    header,
    separator,
    body
  )
  // tslint:disable-next-line: no-console
  console.info(`%cLooking for API Access? We got you fam.%c %c${URL}`, externalLink, separator, link)
  // tslint:disable-next-line: no-console
  //   console.info(, link)
}
