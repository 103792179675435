import { colorOptions } from "../base-colors"
import { braidArrays, generateDarkColorList, generateLightColorList } from "../theme-colors"
import { ITheme } from "./default"

export const purplesTheme: ITheme = {
  putColor: colorOptions.jaguar[500],
  callColor: colorOptions.hotPink[500],
  lightPutColor: colorOptions.jaguar[300],
  lightCallColor: colorOptions.hotPink[300],
  heatmap: colorOptions.videoPurple,
  currentColor: colorOptions.neonPurple[500],
  median: colorOptions.blue[500],
  minMax: colorOptions.jaguar[500],
  ps: colorOptions.greenYellow[500],
  candlestick: {
    up: colorOptions.jaguar[500],
    down: colorOptions.videoPurple[500],
  },
  barCharts: braidArrays(
    generateDarkColorList(colorOptions.jaguar),
    generateDarkColorList(colorOptions.hotPink),
    generateLightColorList(colorOptions.videoPurple)
  ),
  lineCharts: braidArrays(
    generateDarkColorList(colorOptions.jaguar),
    generateDarkColorList(colorOptions.hotPink),
    generateLightColorList(colorOptions.videoPurple)
  ),
}
