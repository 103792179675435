import colorOptions from "../base-colors"
import { ITheme } from "./default"

export const fabioTheme: ITheme = {
  colors: [
    colorOptions.fabioYellow[500],
    colorOptions.fabioGold[500],
    colorOptions.fabioOrange[500],
    colorOptions.fabioOrangeGold[500],
    colorOptions.fabioTile[500],
    colorOptions.fabioPink[500],
    colorOptions.fabioPlum[500],
    colorOptions.fabioPinkPurple[500],
    colorOptions.fabioViolet[500],
    colorOptions.fabioPurple[500],
    colorOptions.fabioNavyBlue[500],
    colorOptions.fabioDarkBlue[500],
  ],
  putColor: colorOptions.fabioPink[500],
  callColor: colorOptions.fabioGold[500],
  lightPutColor: colorOptions.fabioTile[500],
  heatmap: colorOptions.fabioOrange,
  gravitySell: colorOptions.fabioPink,
  gravityBuy: colorOptions.fabioGold,
  lightCallColor: colorOptions.fabioYellow[500],
  currentColor: colorOptions.fabioYellow[500],
  ps: colorOptions.fabioOrange[500],
  minMax: colorOptions.fabioPlum[500],
  median: colorOptions.fabioPink[500],
  barCharts: [
    colorOptions.fabioGold[500],
    colorOptions.fabioYellow[500],
    colorOptions.fabioOrange[500],
    colorOptions.fabioOrangeGold[500],
    colorOptions.fabioTile[500],
    colorOptions.fabioPink[500],
    colorOptions.fabioPlum[500],
    colorOptions.fabioPinkPurple[500],
    colorOptions.fabioViolet[500],
    colorOptions.fabioPurple[500],
    colorOptions.fabioNavyBlue[500],
    colorOptions.fabioDarkBlue[500],
  ],
  lightBarCharts: [
    colorOptions.fabioGold[500],
    colorOptions.fabioYellow[500],
    colorOptions.fabioOrange[500],
    colorOptions.fabioOrangeGold[500],
    colorOptions.fabioTile[500],
    colorOptions.fabioPink[500],
    colorOptions.fabioPlum[500],
    colorOptions.fabioPinkPurple[500],
    colorOptions.fabioViolet[500],
    colorOptions.fabioPurple[500],
    colorOptions.fabioNavyBlue[500],
    colorOptions.fabioDarkBlue[500],
  ],
  lineCharts: [
    colorOptions.fabioYellow[500],
    colorOptions.fabioGold[500],
    colorOptions.fabioOrange[500],
    colorOptions.fabioOrangeGold[500],
    colorOptions.fabioTile[500],
    colorOptions.fabioPink[500],
    colorOptions.fabioPlum[500],
    colorOptions.fabioPinkPurple[500],
    colorOptions.fabioViolet[500],
    colorOptions.fabioPurple[500],
    colorOptions.fabioNavyBlue[500],
    colorOptions.fabioDarkBlue[500],
  ],
  lightLineCharts: [
    colorOptions.fabioYellow[500],
    colorOptions.fabioGold[500],
    colorOptions.fabioOrange[500],
    colorOptions.fabioOrangeGold[500],
    colorOptions.fabioTile[500],
    colorOptions.fabioPink[500],
    colorOptions.fabioPlum[500],
    colorOptions.fabioPinkPurple[500],
    colorOptions.fabioViolet[500],
    colorOptions.fabioPurple[500],
    colorOptions.fabioNavyBlue[500],
    colorOptions.fabioDarkBlue[500],
  ],
}
