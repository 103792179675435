import { NotificationsActions } from "../notification-context"

export function warning(
  {
    title,
    description,
  }: {
    title: string
    description?: string
  },
  dispatch
) {
  if (dispatch && typeof dispatch === "function") {
    dispatch({
      type: NotificationsActions.ADD_NOTIFICATION,
      payload: {
        type: "warning",
        title,
        description,
      },
    })
  }
}
