import { useFirebase } from "../../../hooks/use-firebase"

const ur = process.env.GATSBY_API_URL || process.env.API_URL || ""
const apiUrl = ur === "/api/gql" ? "http://localhost:4000" : ur // 'https://app.pinkswantrading.com'

export async function updateUserSubscriptionStatus() {
  const { auth } = useFirebase()
  const fbUser = auth.currentUser
  const token = await fbUser.getIdToken()

  if (token) {
    try {
      const resp = await fetch(`${apiUrl}/api/auth/payments/get-subscription-session`, {
        method: "GET",

        headers: {
          "Content-Type": "application/json",
          "x-auth": token,
        },
      })
      const body = await resp.json()
      return body
    } catch (error) {
      console.error(error.message)
    }
  }
}
