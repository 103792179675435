import { colorOptions } from "../base-colors"
import { braidArrays, generateDarkColorList, generateLightColorList } from "../theme-colors"
import { ITheme } from "./default"

export const bluesTheme: ITheme = {
  putColor: colorOptions.blue[500],
  callColor: colorOptions.fabioNavyBlue[500],
  lightPutColor: colorOptions.blue[300],
  lightCallColor: colorOptions.fabioNavyBlue[300],
  heatmap: colorOptions.fabioDarkBlue,
  currentColor: colorOptions.green[500],
  median: colorOptions.blue[500],
  minMax: colorOptions.blue[500],
  ps: colorOptions.neonBlue[500],
  candlestick: {
    up: colorOptions.blue[500],
    down: colorOptions.videoPurple[100],
  },
  barCharts: braidArrays(
    generateDarkColorList(colorOptions.blue),
    generateDarkColorList(colorOptions.neonBlue),
    generateLightColorList(colorOptions.fabioNavyBlue)
  ),
  lineCharts: braidArrays(
    generateDarkColorList(colorOptions.blue),
    generateDarkColorList(colorOptions.neonBlue),
    generateLightColorList(colorOptions.fabioNavyBlue)
  ),
}
