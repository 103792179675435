import React from "react"
import { GlobalCalculatorProvider } from "./calculator/calculator"
import { GlobalLayoutProvider } from "./layout/layout-context"
import { GlobalMenuProvider } from "./menu-context/ menu-context"
import { GlobalNotificationProvider } from "./notification/notification-context"
import { GlobalThemeProvider } from "./theme/theme-context"
import { GlobalUserProvider } from "./user/user-context"

export const ContextWrapper = ({ children }) => {
  return (
    <GlobalMenuProvider>
      <GlobalUserProvider>
        <GlobalThemeProvider>
          <GlobalLayoutProvider>
            <GlobalCalculatorProvider>
              <GlobalNotificationProvider>{children}</GlobalNotificationProvider>
            </GlobalCalculatorProvider>
          </GlobalLayoutProvider>
        </GlobalThemeProvider>
      </GlobalUserProvider>
    </GlobalMenuProvider>
  )
}
