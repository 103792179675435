import React, { createContext, FC, useReducer } from "react"
import { getUniqueKey } from "../../utils/get-unique-key"
import { NotificationCard } from "./notification-helpers/notification-card"
export type NotificationStatusType = "info" | "warning" | "error" | "default"
export interface INotification {
  id?: string
  type: NotificationStatusType
  title: string
  description?: string
}

export interface INotificationState {
  notifications: Map<string, INotification>
}
export const NotificationsActions = {
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
  REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",
}
export type NotificationActionTypes = keyof typeof NotificationsActions

const initialNotifications: INotificationState = {
  notifications: new Map(),
}
export const GlobalNotificationState = createContext<INotificationState>(initialNotifications)
export const GlobalNotificationDispatch = createContext(null)

function notificationReducer(state: INotificationState, action: { type: string; payload?: any }) {
  switch (action.type as NotificationActionTypes) {
    case NotificationsActions.ADD_NOTIFICATION: {
      const id = getUniqueKey(".noti")
      const notifications = state.notifications.set(id, {
        ...action.payload,
        id,
      })
      return {
        notifications,
      }
    }
    case NotificationsActions.REMOVE_NOTIFICATION: {
      state.notifications.delete(action.payload)
      return {
        notifications: state.notifications,
      }
    }

    default: {
      return state
    }
  }
}

export const GlobalNotificationProvider: FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer(notificationReducer, initialNotifications, function (): any {
    return initialNotifications as INotificationState
  })

  function removeNotification(id: string) {
    dispatch({
      type: NotificationsActions.REMOVE_NOTIFICATION,
      payload: id,
    })
  }

  return (
    <GlobalNotificationState.Provider value={state as INotificationState}>
      <GlobalNotificationDispatch.Provider value={dispatch}>
        <div
          className={`${
            state.notifications.size > 0 ? "fixed" : "hidden"
          } [z-index:3000]  top-1 right-1 mx-auto flex flex-col gap-2 min-w-fit p-3`}
        >
          {[...state.notifications.values()].map((notification: INotification) => {
            return (
              <NotificationCard
                key={getUniqueKey(".notification")}
                remove={() => removeNotification(notification.id)}
                onClick={() =>
                  dispatch({
                    type: NotificationsActions.REMOVE_NOTIFICATION,
                    payload: notification.id,
                  })
                }
                {...notification}
              />
            )
          })}
        </div>
        {children}
      </GlobalNotificationDispatch.Provider>
    </GlobalNotificationState.Provider>
  )
}
