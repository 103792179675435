import { colorOptions } from "../base-colors"
import { braidArrays, generateDarkColorList, generateLightColorList } from "../theme-colors"
import { ITheme } from "./default"

const barLineColors = braidArrays(
  generateDarkColorList(colorOptions.neonPink),
  generateDarkColorList(colorOptions.neonBlue),
  generateLightColorList(colorOptions.neonOrange),
  generateLightColorList(colorOptions.neonPink),
  generateLightColorList(colorOptions.neonBlue),
  generateDarkColorList(colorOptions.neonOrange),
  generateDarkColorList(colorOptions.neonPink),
  generateDarkColorList(colorOptions.neonBlue),
  generateLightColorList(colorOptions.neonOrange)
)

export const neonTheme: ITheme = {
  putColor: colorOptions.neonGreen[500],
  callColor: colorOptions.neonPurple[500],
  lightPutColor: colorOptions.neonGreen[300],
  lightCallColor: colorOptions.neonPurple[300],
  increase: colorOptions.neonGreen[500],
  decrease: colorOptions.neonRed[500],
  heatmap: colorOptions.neonPink,
  currentColor: colorOptions.neonGreen[500],
  median: colorOptions.neonOrange[500],
  minMax: colorOptions.neonPink[500],
  ps: colorOptions.neonBlue[500],
  highlightColor: colorOptions.neonOrange["500"],
  candlestick: {
    up: colorOptions.neonGreen[500],
    down: colorOptions.neonRed[500],
  },

  barCharts: barLineColors,
  lightBarCharts: barLineColors,

  lightLineCharts: barLineColors,
  lineCharts: barLineColors,
  thresholdColors: colorOptions.neonPink,
  gravityBuy: colorOptions.neonGreen,
  gravitySell: colorOptions.neonRed,
}
