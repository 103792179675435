/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

// import "firebase/app"
// import "firebase/auth"
// import "firebase/database"

import React from 'react'
import ReactDOM from 'react-dom/client'

import { ContextWrapper } from './src/contexts'
import { DataProviders } from './src/data/providers'

import './src/styles/global.css'
import './src/styles/main.scss'

export const wrapRootElement = ({ element }) => {
  return (
    <DataProviders>
      <ContextWrapper>{element}</ContextWrapper>
    </DataProviders>
  )
}
export const replaceHydrateFunction = function () {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}
