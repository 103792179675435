import { defaultColorTheme, fabioTheme, neonTheme } from "./themes"
import { getThemeObject } from "./themes/theme-utils"

export function braidArrays(...arrays) {
  const braided = []
  for (let [ii, array] of arrays.entries()) {
    for (const [i, arr] of array.entries()) {
      braided.splice(i + ii++, 0, arr)
    }
  }

  return braided
}

export function generateDarkColorList(colors: any): string[] {
  const order = ["500", "600", "700", "800", "400", "300"]
  return order.map(o => colors[o])
}
export function generateLightColorList(colors: any): string[] {
  const order = ["400", "300", "500", "200", "100", "50"]
  return order.map(o => colors[o])
}
const fromGenerator = [
  { name: "green", color: "#13CE66" },
  { name: "yellow", color: "#FFC82C" },
  { name: "red", color: "#E93765" },
  { name: "blue", color: "#1FB6FF" },
  { name: "purple", color: "#590CB8" },
]

// for (const [n, sh] of Object.entries(baseColors)) {
//   fromGenerator.push({ name: n, color: sh[500] })
// }

export function generateCustomColors() {
  const customs = {
    default: defaultColorTheme,
    fabio: fabioTheme,
    neon: neonTheme,
    // blues: bluesTheme,
  }
  const allColors = fromGenerator.reduce((acc, col) => {
    acc[col.name] = getThemeObject(col.color)
    return acc
  }, customs)

  return allColors || customs
}
export const customThemes = generateCustomColors()

export function getGvolTheme(userTheme?: string) {
  return userTheme ? { ...customThemes.default, ...customThemes?.[userTheme] } : customThemes.default
}

export function getThemeColor(color, userTheme?: string, defaultColor?) {
  const selectedTheme = getGvolTheme(userTheme || "")
  switch (color) {
    case "primary": {
      return selectedTheme.primary
    }
    case "highlight": {
      return selectedTheme.highlight
    }
    case "secondary": {
      return selectedTheme.secondary
    }
    case "seven": {
      return selectedTheme.currentColor
    }
    case "sevenToThirty": {
      return selectedTheme.highlightColor
    }
    case "ninety": {
      return selectedTheme.secondary
    }
    case "positive": {
      return selectedTheme?.positive
    }
    case "highlight": {
      return selectedTheme?.highlightColor
    }
    case "negative": {
      return selectedTheme?.negative
    }
    case "put":
    case "puts":
    case "put-sold":
    case "puts-sold": {
      return selectedTheme?.putColor
    }
    case "put-bought":
    case "puts-bought": {
      return selectedTheme?.lightPutColor
    }
    case "call":
    case "calls":
    case "call-sold":
    case "calls-sold": {
      return selectedTheme?.callColor
    }
    case "call-bought":
    case "calls-bought": {
      return selectedTheme?.lightCallColor
    }
    case "current":
    case "indexPrice": {
      return selectedTheme?.currentColor
    }
    case "median":
    case "o3": {
      return selectedTheme?.median
    }
    case "minimum":
    case "maximum":
    case "o1": {
      return selectedTheme?.minMax
    }
    case "pTwentyFive":
    case "o2":
    case "pSeventyFive": {
      return selectedTheme?.ps
    }
    case "bybit_BTC-USD":
    case "bybit_ETH-USD":
    case "bybit":
    case "BYBIT": {
      return selectedTheme?.colorOptions?.bybitYellow[400]
    }
    case "binance_BTC-USDT":
    case "binance_ETH-USDT":
    case "binance":
    case "BINANCE": {
      return selectedTheme?.colorOptions?.binanceYellow[400]
    }
    case "deribit_BTC-USD":
    case "deribit_ETH-USD":
    case "deribit":
    case "DERIBIT": {
      return selectedTheme?.colorOptions?.deribitGreen[500]
    }
    case "ledgerx":
    case "LEDGERX": {
      return selectedTheme?.colorOptions?.ledgerXRed[500]
    }
    case "delta":
    case "DELTA": {
      return selectedTheme?.colorOptions?.deltaExchangeBerry[500]
    }
    case "okex_BTC-USD":
    case "okex_ETH-USD":
    case "okex":
    case "OKEX": {
      return selectedTheme?.colorOptions?.okexPurple[600]
    }
    case "okex_ETH-USDT":
    case "okex_BTC-USDT": {
      return selectedTheme?.colorOptions?.pink[600]
    }
    case "okex_BTC-USDC":
    case "okex_ETH-USDC": {
      return selectedTheme?.colorOptions?.blue[100]
    }
    case "cme":
    case "CME": {
      return selectedTheme?.colorOptions?.cmeGreen[500]
    }
    case "friktion":
    case "FRIKTION": {
      return selectedTheme?.colorOptions?.friktionPurple[500]
    }
    case "kraken_BTC-USD":
    case "kraken_ETH-USD":
    case "kraken":
    case "KRAKEN":
    case "Kraken": {
      return selectedTheme?.colorOptions?.krakenPurple[600]
    }
    case "bitmex_BTC-USD":
    case "bitmex_ETH-USD":
    case "bitmex":
    case "Bitmex":
    case "BITMEX": {
      return selectedTheme?.colorOptions?.bitmexRed[600]
    }
    case "huobi":
    case "HUOBI":
    case "Huobi": {
      return selectedTheme?.colorOptions?.huobiBlue[600]
    }
    case "bitcom":
    case "BITCOM": {
      return selectedTheme?.colorOptions?.bitcomGreen[800]
    }
    case "ribbon":
    case "RIBBON": {
      return selectedTheme?.colorOptions?.neonBlue[500]
    }
    case "lyra":
    case "LYRA": {
      return selectedTheme?.colorOptions?.lyraGreen[700]
    }
    case "eth":
    case "Eth":
    case "ETH": {
      return selectedTheme?.colorOptions?.ethereum[500]
    }
    case "btc":
    case "Btc":
    case "BTC": {
      return selectedTheme?.colorOptions?.bitcoin[500]
    }
    case "adDarkBlue": {
      return selectedTheme?.colorOptions?.amberDataDarkBlue[500]
    }
    case "adOrange": {
      return selectedTheme?.colorOptions?.amberDataOrange[500]
    }
    case "1_buy": {
      return selectedTheme?.gravityBuy?.["400"]
    }
    case "2_buy": {
      return selectedTheme?.gravityBuy?.["500"]
    }
    case "3_buy": {
      return selectedTheme?.gravityBuy?.["600"]
    }
    case "4_buy": {
      return selectedTheme?.gravityBuy?.["700"]
    }
    case "5_buy": {
      return selectedTheme?.gravityBuy?.["800"]
    }
    case "6_buy": {
      return selectedTheme?.gravityBuy?.["200"]
    }
    case "1_sell": {
      return selectedTheme?.gravitySell?.["400"]
    }
    case "2_sell": {
      return selectedTheme?.gravitySell?.["500"]
    }
    case "3_sell": {
      return selectedTheme?.gravitySell?.["600"]
    }
    case "4_sell": {
      return selectedTheme?.gravitySell?.["700"]
    }
    case "5_sell": {
      return selectedTheme?.gravitySell?.["800"]
    }
    case "6_sell": {
      return selectedTheme?.gravitySell?.["200"]
    }
    default: {
      return defaultColor || color
    }
  }
}
