import React, { createContext, FC, useReducer } from "react"

interface CalcState {
  ivCalculator: boolean
  probabilityCalculator: boolean
  bsmCalculator: boolean
}

const initialState = {
  ivCalculator: false,
  probabilityCalculator: false,
  bsmCalculator: false,
}

export const GlobalCalculatorState = createContext<CalcState>(initialState)
export const GlobalCalculatorDispatch = createContext(null)

export const calculatorActions = {
  SET_CALCULATOR: "SET_CALCULATOR",
}

function reducer(state: CalcState, action: { type: string; payload?: any }) {
  switch (action.type) {
    case calculatorActions.SET_CALCULATOR: {
      return { ...state, [action?.payload?.name]: action?.payload?.value }
    }
    default: {
      return state
    }
  }
}

export const GlobalCalculatorProvider: FC<any> = ({ children }) => {
  //   const [loading, setLoading] = useState(true)
  const [state, dispatch] = useReducer(reducer, initialState)

  //   if (loading) {
  //     return <LoadingPage title="Setting Theme" />
  //   }

  return (
    <GlobalCalculatorState.Provider value={state}>
      <GlobalCalculatorDispatch.Provider value={dispatch}>{children}</GlobalCalculatorDispatch.Provider>
    </GlobalCalculatorState.Provider>
  )
}
