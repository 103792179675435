import React, { createContext, FC, useContext, useEffect, useReducer, useState } from "react"
import { Layouts } from "react-grid-layout"
import { LoadingPage } from "../../components/loading-page/loading-page"
import { getItem, parseObj, setItem, stringifyObj } from "../../utils/token-helpers"
import { GlobalUserState } from "../user/user-context"
import { addOrUpdateLayouts } from "../../data/layout-requests"

const LAYOUT_KEY = "LAYOUT"
export type LayoutTypes = "top" | "left" | "right" | "full"
export interface IAppLayout {
  orientation: LayoutTypes
  isMobile: boolean
  layouts?: { [key: string]: Layouts }
}
export type LayoutActionTypes = "SET_LAYOUT" | "SET_ORIENTATION"
export const LayoutActions = {
  SET_LAYOUT: "SET_LAYOUT",
  SET_ORIENTATION: "SET_ORIENTATION",
}

const initialLayout: IAppLayout = {
  orientation: "top",
  isMobile: false,
  layouts: {},
  ...JSON.parse(getItem(LAYOUT_KEY)),
}
export const GlobalLayoutState = createContext<IAppLayout>(initialLayout)
export const GlobalLayoutDispatch = createContext(null)

function layoutReducer(state: IAppLayout, action: { type: string; payload?: any }) {
  switch (action.type as LayoutActionTypes) {
    case LayoutActions.SET_LAYOUT: {
      const { token, ...latestLayouts } = action.payload

      const newLayout = {
        ...state,
        orientation: state.orientation,
        layouts: { ...state.layouts, ...latestLayouts },
      }

      setItem(LAYOUT_KEY, stringifyObj(newLayout))
      if (token) {
        addOrUpdateLayouts(newLayout, token)
      } else {
        console.warn("Layouts not saved. Insufficient Info.")
      }
      return newLayout
    }

    case LayoutActions.SET_ORIENTATION: {
      const newOrientation = { ...state, orientation: action.payload }
      setItem(LAYOUT_KEY, stringifyObj(newOrientation))
      return newOrientation
    }

    default: {
      return state
    }
  }
}

export const GlobalLayoutProvider: FC<any> = ({ children }) => {
  const [loading, setLoading] = useState(true)

  const [state, dispatch] = useReducer(layoutReducer, initialLayout, function (): any {
    return initialLayout as IAppLayout
  })

  const user = useContext(GlobalUserState)

  useEffect(() => {
    const getInit = async () => {
      const userLayout = user?.layouts ? parseObj(user?.layouts)?.layouts : parseObj(getItem(LAYOUT_KEY))?.layouts

      if (userLayout) {
        dispatch({
          type: LayoutActions.SET_LAYOUT,
          payload: userLayout,
        })
      }

      setLoading(false)
    }
    getInit()
  }, [])
  if (loading) {
    return <LoadingPage title="Fetching Layouts" />
  }
  return (
    <GlobalLayoutState.Provider value={state as IAppLayout}>
      <GlobalLayoutDispatch.Provider value={dispatch}>{children}</GlobalLayoutDispatch.Provider>
    </GlobalLayoutState.Provider>
  )
}
