import React, { useEffect, useState } from "react"

import { ApolloProvider } from "@apollo/client"
import { loadStripe } from "@stripe/stripe-js"
import { client } from "./apollo/apollo-client"
import { LoadingPage } from "../components/loading-page/loading-page"

export const DataProviders = props => {
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    async function addPaymentRequirements() {
      const apiKey = process.env.STRIPE_PUBLIC_KEY

      try {
        await loadStripe(apiKey)
        setLoading(false)
      } catch (error) {
        console.log(" ee >>", error.message)
        setLoading(false)
      }
    }
    addPaymentRequirements()
  }, [])
  if (loading) {
    return <LoadingPage title="Connecting Data" />
  }
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>
}
