import { braidArrays } from "../theme-colors"

export function getLighterColor(color: string) {
  const hsl: { h: number; s: number; l: number } = hexToHSL(color)
  const lighterHSL: { h: number; s: number; l: number } = { ...hsl, l: 80 }
  return hslToHEX(lighterHSL)
}
export function getDarkerColor(color: string) {
  const hsl: { h: number; s: number; l: number } = hexToHSL(color)
  const lighterHSL: { h: number; s: number; l: number } = { ...hsl, l: 20 }
  return hslToHEX(lighterHSL)
}

export function getThemeObject(mainColor: string) {
  const [primary, secondary, highlight] = harmonize(hexToHSL(mainColor), 150, 210, 60)
  const primaryColorObject = getNewColor(primary)
  const secondaryColorObject = getNewColor(secondary)
  const highlightColorObject = getNewColor(highlight)

  return {
    primary: primaryColorObject.shades["500"],
    secondary: secondaryColorObject.shades["500"],
    highlight: highlightColorObject.shades["500"],
    increase: primaryColorObject.hex, // string
    decrease: secondaryColorObject.hex, // string
    heatmap: primaryColorObject.shades, // { 50 >> 900 }
    currentColor: highlightColorObject.hex, // contrast
    median: primaryColorObject.shades[700], // opposite
    minMax: secondaryColorObject.shades[500], // darker hue
    ps: secondaryColorObject.shades[300], // medium hue
    gravityBuy: primaryColorObject.shades,
    gravitySell: secondaryColorObject.shades,
    candlestick: {
      up: primaryColorObject.hex, // lighterColor
      down: secondaryColorObject.hex, // darkerColor
    },
    barCharts: braidArrays(
      generateDarkColorList(primaryColorObject.shades),
      generateDarkColorList(secondaryColorObject.shades),
      generateDarkColorList(highlightColorObject.shades),
      generateDarkColorList(primaryColorObject.shades),
      generateDarkColorList(secondaryColorObject.shades),
      generateDarkColorList(highlightColorObject.shades),
      generateDarkColorList(primaryColorObject.shades),
      generateDarkColorList(secondaryColorObject.shades),
      generateDarkColorList(highlightColorObject.shades)
    ), //  string[]
    lightBarCharts: braidArrays(
      generateLightColorList(primaryColorObject.shades),
      generateLightColorList(secondaryColorObject.shades),
      generateLightColorList(highlightColorObject.shades),

      generateLightColorList(primaryColorObject.shades),
      generateLightColorList(secondaryColorObject.shades),
      generateLightColorList(highlightColorObject.shades),

      generateLightColorList(primaryColorObject.shades),
      generateLightColorList(highlightColorObject.shades),
      generateLightColorList(secondaryColorObject.shades)
    ), //  string[]
    lineCharts: braidArrays(
      generateDarkColorList(primaryColorObject.shades),
      generateDarkColorList(secondaryColorObject.shades),
      generateDarkColorList(highlightColorObject.shades),
      generateDarkColorList(primaryColorObject.shades),
      generateDarkColorList(secondaryColorObject.shades),
      generateDarkColorList(highlightColorObject.shades),
      generateDarkColorList(primaryColorObject.shades),
      generateDarkColorList(secondaryColorObject.shades),
      generateDarkColorList(highlightColorObject.shades)
    ), //  string[]
    lightLineCharts: braidArrays(
      generateLightColorList(primaryColorObject.shades),
      generateLightColorList(secondaryColorObject.shades),
      generateLightColorList(highlightColorObject.shades),
      generateLightColorList(primaryColorObject.shades),
      generateLightColorList(secondaryColorObject.shades),
      generateLightColorList(highlightColorObject.shades),
      generateLightColorList(primaryColorObject.shades),
      generateLightColorList(secondaryColorObject.shades),
      generateLightColorList(highlightColorObject.shades)
    ), //  string[]
    putColor: primaryColorObject?.shades[500], // string
    callColor: secondaryColorObject.shades[500], // string
    lightPutColor: primaryColorObject?.shades[600], // string
    lightCallColor: secondaryColorObject.shades[600], // string
    thresholdColors: primaryColorObject?.shades,
    highlightColor: secondaryColorObject.shades[500],
  }
}

export function getNewColor(hex) {
  const colorObj = {
    hex,
    hsl: hexToHSL(hex),
    shades: getNewShades(hex),
  }

  return colorObj
}
export function generateDarkColorList(colors: any): string[] {
  const order = ["500", "600", "700", "800", "400", "300"]
  return order.map(o => colors[o])
}
export function generateLightColorList(colors: any): string[] {
  const order = ["300", "400", "500", "200", "100", "50"]
  return order.map(o => colors[o])
}
export function getNewShades(props) {
  // check if prop is string
  if (typeof props === "string") {
    // initiate color variables
    let hex
    // determine if props is hex
    if (/^#[\da-f]{6}$/i.test(props)) hex = props
    props = {
      hex,
      shades: [
        { name: "50", lightness: 98 },
        { name: "100", lightness: 95 },
        { name: "200", lightness: 90 },
        { name: "300", lightness: 82 },
        { name: "400", lightness: 64 },
        { name: "500", lightness: 46 },
        { name: "600", lightness: 33 },
        { name: "700", lightness: 24 },
        { name: "800", lightness: 14 },
        { name: "900", lightness: 7 },
        { name: "950", lightness: 4 },
      ],
    }
  }

  // convert hex to rgb
  props.hsl = hexToHSL(props.hex)
  // generate colors
  props.shades = props.shades.map(({ name, lightness }) => {
    // deconstruct base hsl
    const { h, s } = props.hsl
    const hsl = { h, s, l: lightness }
    return { name, lightness, hsl, hex: hslToHEX(hsl) }
  })
  return props.shades.reduce((acc, { hex, name }) => {
    acc[name] = hex
    return acc
  }, {})
}

function harmonize(color, start, end, interval) {
  const { h, s, l } = color
  const colors = [hslToHEX(color)]
  for (let i = start; i <= end; i += interval) {
    const h1 = (h + i) % 360

    const c1 = hslToHEX({ h: h1, s, l })
    colors.push(c1)
  }

  return colors
}

export function hslToHEX({ h, s, l }) {
  l /= 100
  const a = (s * Math.min(l, 1 - l)) / 100
  const f = n => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0") // convert to Hex and prefix "0" if needed
  }
  return `#${f(0)}${f(8)}${f(4)}`
}

export function hexToHSL(hex: string) {
  const result = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i.exec(hex)
  try {
    let r = Number.parseInt(result[1], 16)
    let g = Number.parseInt(result[2], 16)
    let b = Number.parseInt(result[3], 16)

    ;(r /= 255), (g /= 255), (b /= 255)
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b)
    let h,
      s,
      l = (max + min) / 2
    if (max == min) {
      h = s = 0 // achromatic
    } else {
      const d = max - min
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
      switch (max) {
        case r: {
          h = (g - b) / d + (g < b ? 6 : 0)
          break
        }
        case g: {
          h = (b - r) / d + 2
          break
        }
        case b: {
          h = (r - g) / d + 4
          break
        }
      }
      h /= 6
    }
    const HSL = new Object() as { h: number; s: number; l: number }
    HSL["h"] = Math.round(h * 360)
    HSL["s"] = Math.round(s * 100)
    HSL["l"] = Math.round(l * 100)
    return HSL
  } catch {
    console.log(hex)
    return { h: 0, s: 0, l: 0 }
  }
}

export function hexToRGB(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([\da-f])([\da-f])([\da-f])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })
  const result = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i.exec(hex)
  return result
    ? {
        r: Number.parseInt(result[1], 16),
        g: Number.parseInt(result[2], 16),
        b: Number.parseInt(result[3], 16),
      }
    : {
        r: 255,
        g: 255,
        b: 255,
      }
}
