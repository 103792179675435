import { colorOptions } from "../base-colors"
import { braidArrays, generateDarkColorList, generateLightColorList } from "../theme-colors"

const chartLineColor = "rgba(100,100, 100 ,0.4)"
const lightLineColor = "rgba(100,100, 100 ,0.1)"
const darkLineColor = "rgba(100,100, 100 ,0.8)"

type ColorObject = {
  ["50"]: string
  ["100"]: string
  ["200"]: string
  ["300"]: string
  ["400"]: string
  ["500"]: string
  ["600"]: string
  ["700"]: string
  ["800"]: string
  ["900"]: string
}

export interface IThemeOptions {
  fontFamily: string
  fontSize: string
  fontThin: string
  fontThick: string
  chartLineColor: string
  lightLineColor: string
  darkLineColor: string
  putColor: string
  callColor: string
  lightPutColor: string
  increase: string
  positive: string
  decrease: string
  negative: string
  highlightColor: string
  lightCallColor: string
  xAxis: any
  axis: any
  yAxis: any
  defaultToolLineColor: string
  heatmap: {
    [k: string]: string
  }
  currentColor: string
  median: string
  minMax: string
  ps: string
  candlestick?: {
    up: string
    down: string
  }
  barCharts: string[]
  lineCharts: string[]
  lightBarCharts: string[]
  lightLineCharts: string[]
  colors: string[]
  thresholdColors: ColorObject
  gravitySell: ColorObject
  gravityBuy: ColorObject
  colorOptions: typeof colorOptions
}

export type ITheme = Partial<IThemeOptions>

const barCharts = braidArrays(
  generateDarkColorList(colorOptions.amberDataDarkBlue),
  generateDarkColorList(colorOptions.amberDataOrange),
  generateDarkColorList(colorOptions.amberDataLightBlue),
  generateDarkColorList(colorOptions.amberDataRed),
  generateDarkColorList(colorOptions.amberDataLavender),
  generateDarkColorList(colorOptions.amberDataYellow),
  generateDarkColorList(colorOptions.amberDataTeal),
  generateDarkColorList(colorOptions.amberDataPink),
  generateDarkColorList(colorOptions.amberDataGreen),
  generateDarkColorList(colorOptions.amberDataPurple),
  generateLightColorList(colorOptions.amberDataDarkBlue),
  generateLightColorList(colorOptions.amberDataOrange),
  generateLightColorList(colorOptions.amberDataLightBlue),
  generateLightColorList(colorOptions.amberDataYellow),
  generateLightColorList(colorOptions.amberDataLavender),
  generateLightColorList(colorOptions.amberDataRed),
  generateLightColorList(colorOptions.amberDataTeal),
  generateLightColorList(colorOptions.amberDataPink),
  generateLightColorList(colorOptions.amberDataGreen),
  generateLightColorList(colorOptions.amberDataPurple)
)
const lightBarCharts = braidArrays(
  generateLightColorList(colorOptions.amberDataDarkBlue),
  generateLightColorList(colorOptions.amberDataOrange),
  generateLightColorList(colorOptions.amberDataLightBlue),
  generateLightColorList(colorOptions.amberDataRed),
  generateLightColorList(colorOptions.amberDataLavender),
  generateDarkColorList(colorOptions.amberDataYellow),
  generateLightColorList(colorOptions.amberDataTeal),
  generateLightColorList(colorOptions.amberDataPink),
  generateLightColorList(colorOptions.amberDataGreen),
  generateLightColorList(colorOptions.amberDataPurple),
  generateDarkColorList(colorOptions.amberDataDarkBlue),
  generateDarkColorList(colorOptions.amberDataOrange),
  generateDarkColorList(colorOptions.amberDataLightBlue),
  generateDarkColorList(colorOptions.amberDataRed),
  generateDarkColorList(colorOptions.amberDataLavender),
  generateDarkColorList(colorOptions.amberDataYellow),
  generateDarkColorList(colorOptions.amberDataTeal),
  generateDarkColorList(colorOptions.amberDataPink),
  generateDarkColorList(colorOptions.amberDataGreen),
  generateDarkColorList(colorOptions.amberDataPurple)
)
const lineCharts = braidArrays(
  generateDarkColorList(colorOptions.amberDataDarkBlue),
  generateDarkColorList(colorOptions.amberDataOrange),
  generateDarkColorList(colorOptions.amberDataLightBlue),
  generateDarkColorList(colorOptions.amberDataRed),
  generateDarkColorList(colorOptions.amberDataLavender),
  generateDarkColorList(colorOptions.amberDataYellow),
  generateDarkColorList(colorOptions.amberDataTeal),
  generateDarkColorList(colorOptions.amberDataPink),
  generateDarkColorList(colorOptions.amberDataGreen),
  generateDarkColorList(colorOptions.amberDataPurple),
  generateLightColorList(colorOptions.amberDataDarkBlue),
  generateLightColorList(colorOptions.amberDataOrange),
  generateLightColorList(colorOptions.amberDataLightBlue),
  generateLightColorList(colorOptions.amberDataYellow),
  generateLightColorList(colorOptions.amberDataLavender),
  generateLightColorList(colorOptions.amberDataRed),
  generateLightColorList(colorOptions.amberDataTeal),
  generateLightColorList(colorOptions.amberDataPink),
  generateLightColorList(colorOptions.amberDataGreen),
  generateLightColorList(colorOptions.amberDataPurple)
)
const lightLineCharts = braidArrays(
  generateLightColorList(colorOptions.amberDataDarkBlue),
  generateLightColorList(colorOptions.amberDataOrange),
  generateLightColorList(colorOptions.amberDataLightBlue),
  generateLightColorList(colorOptions.amberDataRed),
  generateLightColorList(colorOptions.amberDataLavender),
  generateDarkColorList(colorOptions.amberDataYellow),
  generateLightColorList(colorOptions.amberDataTeal),
  generateLightColorList(colorOptions.amberDataPink),
  generateLightColorList(colorOptions.amberDataGreen),
  generateLightColorList(colorOptions.amberDataPurple),
  generateDarkColorList(colorOptions.amberDataDarkBlue),
  generateDarkColorList(colorOptions.amberDataOrange),
  generateDarkColorList(colorOptions.amberDataLightBlue),
  generateDarkColorList(colorOptions.amberDataYellow),
  generateDarkColorList(colorOptions.amberDataLavender),
  generateDarkColorList(colorOptions.amberDataRed),
  generateDarkColorList(colorOptions.amberDataTeal),
  generateDarkColorList(colorOptions.amberDataPink),
  generateDarkColorList(colorOptions.amberDataGreen),
  generateDarkColorList(colorOptions.amberDataPurple)
)

const candlestick = {
  up: colorOptions.green[500],
  down: colorOptions.red[500],
}

export const defaultColorTheme: ITheme = {
  fontFamily: "Helvetica Neue, Arial, Sans serif",
  fontSize: "1em",
  fontThin: "300",
  fontThick: "700",
  chartLineColor,
  lightLineColor: lightLineColor,
  darkLineColor: darkLineColor,
  increase: colorOptions.green[500],
  decrease: colorOptions.red[500],
  positive: colorOptions.green[500],
  negative: colorOptions.red[500],
  heatmap: colorOptions.primary,
  currentColor: "#13CE66",
  median: "#FFC82C",
  highlightColor: colorOptions.secondary["500"],
  minMax: "#E06DA3",
  ps: "#1FB6FF",
  xAxis: {
    fontFamily: "Helvetica Neue, Arial, Sans serif",
    fontSize: "1em",
    chartLineColor,
  },
  yAxis: {
    fontFamily: "Helvetica Neue, Arial, Sans serif",
    fontSize: "1em",
    chartLineColor,
  },
  axis: {
    fontFamily: "Helvetica Neue, Arial, Sans serif",
    fontSize: "1em",
    chartLineColor,
  },
  candlestick,
  barCharts,
  lightBarCharts,
  lineCharts,
  lightLineCharts,
  colorOptions,
  defaultToolLineColor: colorOptions.highlight[500],
  putColor: colorOptions.secondary[500],
  callColor: colorOptions.amberDataOrange[500],
  lightPutColor: colorOptions.secondary[600],
  lightCallColor: colorOptions.amberDataOrange[600],
  thresholdColors: colorOptions.secondary,
  gravitySell: colorOptions.red,
  gravityBuy: colorOptions.green,
}
