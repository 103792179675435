import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { isBrowser } from "@firebase/util"
import fetch from "isomorphic-fetch"
import { getUserTokenFromLocalStorage } from "../../utils/token-helpers"

let apiUrl = process.env.GATSBY_API_URL || ""

if (apiUrl.indexOf("http") === 0) {
  apiUrl = `${process.env.GATSBY_API_URL}/graphql`
}

const link = new HttpLink({ fetch, uri: apiUrl })

export const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists

  headers = {
    ...headers,
    method: "POST",
  }
  if (isBrowser()) {
    const token = getUserTokenFromLocalStorage()

    // return the headers to the context so httpLink can read them
    headers = token
      ? {
          ...headers,
          ["x-auth"]: token,
        }
      : {
          ...headers,
          ["xxx"]: "please-dont-steal-me",
        }
  }

  return {
    headers,
  }
})

// const tokenManagement = new ApolloLink((operation, forward) => {
//   return asyncMap(forward(operation), response => {
//     // if (operation.operationName !== "GetV2User") {
//     //   getUpdatedTokenAsync()
//     // }
//     return response
//   })
// })

const resetToken = onError(({ networkError, graphQLErrors }) => {
  if (networkError) {
    console.log("Network Error", networkError)
    // const { auth } = useFirebase()
    // if (auth.currentUser) {
    //   signOutCurrentUser()
    //   getUpdatedTokenAsync().then((token: string) => {
    //     setUserTokenToLocalStorage(token)
    //   })
    // }
  }
  if (graphQLErrors) {
    /* eslint-disable  @typescript-eslint/no-unused-vars */
    for (const error of graphQLErrors) {
      // console.log("Graphql Error", error)
      return null
    }
  }
  // signOutCurrentUser()
  // return forward(operation)
})
// const apollo = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: authLink.concat(link),
// })

const apollo = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([authLink, resetToken, link]),
})

export const client = apollo
